@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

// Layout
.layout {
  display: grid;
}

.left {
  grid-column: 1;
  grid-row: 2;

  @media screen and (min-width: var.$mid-width) {
    grid-column: 2;
    grid-row: 1;
  }

  @media screen and (min-width: var.$desktop-width) {
    padding-top: var.$spacing-regular;
    grid-column: 1;
  }
}

.center {
  grid-column: 1;
  grid-row: 1;

  @media screen and (min-width: var.$desktop-width) {
    grid-column: 2;
    grid-row: 1;
  }
}

.right {
  grid-column: 1;
  grid-row: 3;

  @media screen and (min-width: var.$mid-width) {
    grid-column: 1;
    grid-row: 2;
  }

  @media screen and (min-width: var.$desktop-width) {
    padding-top: var.$spacing-regular;
    grid-column: 3;
    grid-row: 1;
  }
}

.inner {
  height: 100%;

  @include mix.flex($direction: column, $align: stretch, $gap: var.$spacing-lg);
}

// Modules
.coverArticles {
  order: -1;
  margin: 0 -1rem;

  @media screen and (min-width: var.$tablet-width) {
    margin: 0;
  }
}

.headline {
  grid-row: 2;

  // Add specificity to override the border-bottom on SEOHomeText
  &[class^='SEOHomeText'] {
    border-bottom: none;
  }
}

.trending {
  > :nth-child(2) {
    margin-top: var.$spacing-sm;
  }

  &:is(.center .trending) {
    display: block;

    @media screen and (min-width: var.$mid-width) {
      display: none;
    }
  }

  &:is(.left .trending) {
    display: none;

    @media screen and (min-width: var.$mid-width) {
      display: block;
    }
  }
}
