@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/on3/theme';

.block {
  h2 {
    @extend %h3, %subheading;

    margin-bottom: var.$spacing-sm;
  }

  .rankings {
    display: grid;
    gap: var.$spacing-sm;
    margin-bottom: var.$spacing-sm;
  }

  .ranking {
    display: grid;
    grid-template-columns: 1.75rem 1fr auto;

    &:hover {
      .name {
        text-decoration: underline;
      }
    }

    span {
      display: block;

      &:last-child {
        @extend %small-gray-text;
      }
    }

    .rank {
      align-self: center;
      font-size: 0.75rem;
      font-weight: 600;
    }

    .team {
      @include mix.flex(
        $justify: 'flex-start',
        $align: 'center',
        $gap: var.$spacing-sm
      );

      figure {
        height: 3rem;
        aspect-ratio: 1 / 1;
        background: #fff;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: var.$radius-xs;
        }
      }

      .name {
        color: var.$black;
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.35px;
      }
    }

    .rating {
      text-align: right;
      align-self: center;

      span:first-child {
        font-size: 0.75rem;
        font-weight: 500;
      }
    }
  }

  .viewAll {
    display: inline-block;
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 600;
    color: var.$on3primary;
    letter-spacing: 1.15px;
    margin-top: var.$spacing-sm;

    &:hover {
      text-decoration: underline;
    }
  }
}
