@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  h2 {
    @extend %h3, %subheading;

    margin-bottom: var.$spacing-sm;

    svg {
      fill: currentcolor;
    }

    a {
      @include mix.flex($gap: var.$spacing-xs);
    }
  }

  > a {
    font-size: 11px;
    text-align: center;
    display: block;
    color: var.$orange;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.15px;
  }

  .list {
    display: grid;

    li + li {
      .content {
        border-top: 1px solid var.$lightNeutral;
      }
    }
  }

  .listItem {
    @include mix.flex($gap: var.$spacing-sm);

    .index {
      flex-shrink: 0;
      height: 2rem;
      width: 2rem;
      border-radius: 100%;
      background-color: var.$focusBackground;
      color: var.$on3primary;
      font-weight: 700;

      @include mix.flex($align: center, $justify: center);
    }

    .content {
      flex: 1;
      padding: var.$spacing-sm 0;

      @include mix.flex($gap: 0.5rem);

      > div {
        flex: 1;
      }
    }

    h3 {
      @extend %h4;

      margin-bottom: 0;

      @include mix.lineClamp(1);
    }

    p {
      font-size: 0.75rem;
      line-height: 1;
      color: var.$gray;

      @include mix.lineClamp(1);
    }

    .tag {
      @include mix.flex($inline: true, $gap: var.$spacing-xs);

      font-family: var.$archivo-narrow;
      text-transform: uppercase;
      font-size: 0.625rem;
      font-weight: 600;
      padding: 3px 6px;

      svg {
        font-size: 0.875rem;
        fill: currentcolor;
        height: 1em;
        width: 1em;
      }

      &[data-type='breaking'] {
        background-color: var.$on3primary;
        color: #fff;
      }

      &[data-type='new'] {
        background-color: #52fefe;
        color: var.$black;
      }

      &[data-type='trending'] {
        background-color: var.$newGreen;
        color: var.$black;
      }

      &[data-type='live'] {
        background-color: #fff96c;
        color: var.$black;

        svg {
          fill: var.$on3primary;
        }
      }

      &[data-type='hot'] {
        background-color: var.$interestBeige;
        color: var.$on3primary;
      }
    }
  }
}
