@use '@on3/styles/variables' as var;

.fanitem {
  display: flex;
  border-bottom: 1px solid var.$neutral;
  align-items: center;
  gap: 10px;
  flex-basis: 100%;

  @media only screen and (min-width: var.$mid-width) {
    flex-basis: 40%;
    flex-grow: 1;
  }

  @media only screen and (min-width: var.$desktop-width) {
    flex-basis: 30%;
  }

  @media only screen and (min-width: 1310px) {
    flex-grow: 0;
  }
}

.favoriteTeam {
  background-color: var.$focusBackground;
  border-bottom: 2px solid var.$gray;
}

.fanitemContainer {
  display: flex;
}

.team {
  color: var.$gray;
  font-family: var.$geist;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.15px;
}

.logo {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var.$neutral;
  background-color: var.$focusBackground;
}

.fanText {
  font-family: var.$geist;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: var.$black;
}

.fanWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.forum {
  padding: 0 10px;
}
