@use '@on3/styles/variables' as var;

.list {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.viewAll {
  font-family: var.$geist;
  font-size: 11px;
  letter-spacing: 1.15px;
}

.footer {
  padding: 10px 0;
}
