@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

:export {
  on3Primary: var.$on3primary;
}

.block {
  .container {
    display: grid;
    gap: var.$spacing-sm;
    background-color: var.$focusBackground;
    padding: var.$spacing-sm;

    h3 {
      @extend %h3;

      font-size: clamp(1rem, 1.25vw, 1.125rem);
      text-align: center;
    }

    input {
      background-color: var.$white;
    }

    button {
      @extend %btn;

      margin-top: var.$spacing-sm;
      width: 100%;
      color: var.$white;
    }

    .message {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 600;
      color: var.$newGreen;

      &[data-type='error'] {
        color: var.$negativeDay;
      }
    }
  }

  .terms {
    font-family: var.$geist;
    margin-top: var.$spacing-sm;
    color: var.$gray;
    font-size: 0.75rem;
    letter-spacing: 0.35px;
    line-height: 1.2;

    a {
      text-decoration: underline;
      color: var.$gray;
    }
  }
}
